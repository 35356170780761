import React from "react"
import { navigate } from "gatsby"
import request from "../../request"
import "normalize.css"
import "../../components/layout.scss"

const Login = () => {
  const username = React.createRef()
  const password = React.createRef()
  const login = () =>
    request
      .login(username.current.value, password.current.value)
      .then(res => {
        if (res.data.user.id === 1) {
          navigate("/admin/news")
        } else {
          navigate("/admin/announcement")
        }
      })
      .catch(err => {
        alert("帳號或密碼不正確")
      })

  const submit = e => {
    if (e.keyCode === 13) {
      // enter
      if (username.current.value && password.current.value) login()
      else alert("所有欄位皆必須填寫")
    }
  }
  return (
    <div className="ad-login">
      <div className="ad-login__box">
        <h3>登入網站系統</h3>
        <div>
          <form>
            <div>
              <input
                type="text"
                placeholder="請輸入帳號"
                autoComplete="no"
                ref={username}
                onKeyDown={submit}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="請輸入密碼"
                autoComplete="no"
                onKeyDown={submit}
                ref={password}
              />
            </div>
          </form>
          <button onClick={login}>登入</button>
        </div>
      </div>
    </div>
  )
}

export default Login
